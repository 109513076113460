<template>
    <component
        :is="tag"
        v-on="$listeners"
    >
        <b-avatar
            size="42"
            :src="user.files[0].full_path"
            class="badge-minimal"
            variant="transparent"
        />
        <div class="chat-info flex-grow-1">
            <h5 class="mb-0">
                {{ user.title ? user.title : "Şikayet başlığı" }}
                <!--                {{ user.il.name }}-->
            </h5>
            <p class="card-text text-truncate">
                {{
                    user.last_message ? user.last_message.message ? user.last_message.message : 'Image' : 'Start Chatting Now'
                }}
            </p>
        </div>
        <div
            v-if="isChatContact"
            class="chat-meta text-nowrap"
        >
            <small class="float-right mb-25 chat-time" v-if="user.last_message">
                {{
                    formatDateToMonthShort(user.last_message.updated_at, {
                        hour: 'numeric',
                        minute: 'numeric'
                    })
                }}
            </small>
            <b-badge
                v-if="user.message_not_road_count>0"
                pill
                variant="primary"
                class="float-right"
            >
                {{ user.message_not_road_count }}
            </b-badge>
        </div>
    </component>
</template>

<script>

import {BAvatar, BBadge} from 'bootstrap-vue'
import {formatDateToMonthShort} from '@core/utils/filter'
import useChat from './useChat'
import {isToday} from "@core/utils/utils";

export default {
    name: "ChatContact",
    components: {
        BAvatar,
        BBadge,
    },
    props: {
        tag: {
            type: String,
            default: 'div',
        },
        user: {
            type: Object,
            required: true,
        },
        isChatContact: {
            type: Boolean,
            dedfault: false,
        },
    },
    methods: {
        formatDateToMonthShort(value, toTimeForCurrentDay = true) {
            const date = new Date(value)
            let formatting = {month: 'short', day: 'numeric'}

            if (toTimeForCurrentDay && isToday(date)) {
                formatting = {hour: 'numeric', minute: 'numeric'}
            }

            return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
        },
    },
}
</script>

<style scoped>

</style>
