<template>
    <!--     Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div style="height: inherit" v-if="activeChat!==null">
        <div
            class="body-content-overlay"
        />

        <!-- Main Area -->
        <section class="chat-app-window">

            <!-- Start Chat Logo -->
            <!--            <div-->
            <!--                v-if="activeChat.message_count<1 && !isStartConversation"-->
            <!--                class="start-chat-area"-->
            <!--            >-->
            <!--                <div class="mb-1 start-chat-icon">-->
            <!--                    <feather-icon-->
            <!--                        icon="MessageSquareIcon"-->
            <!--                        size="56"-->
            <!--                    />-->
            <!--                </div>-->
            <!--                <h4-->
            <!--                    class="sidebar-toggle start-chat-text"-->
            <!--                    @click="startConversation"-->
            <!--                >-->
            <!--                    Start Conversation-->
            <!--                </h4>-->
            <!--            </div>-->

            <!-- Chat Content -->
            <div

                class="active-chat"
            >
                <!-- Chat Navbar -->
                <div class="chat-navbar">
                    <header class="chat-header">

                        <!-- Avatar & Name -->
                        <div class="d-flex align-items-center">

                            <!-- Toggle Icon -->
                            <div class="sidebar-toggle d-block d-lg-none mr-1">
                                <feather-icon
                                    icon="MenuIcon"
                                    class="cursor-pointer"
                                    size="21"
                                    @click="mqShallShowLeftSidebar = true"
                                />
                            </div>

                            <b-avatar
                                size="36"
                                :src="activeChat.files[0]?activeChat.files[0].full_path:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'"
                                class="mr-1 cursor-pointer badge-minimal"

                            />
                            <h6 class="mb-0">
                                {{ activeChat.title ? activeChat.title : "Şikayet başlığı" }}
                            </h6>
                        </div>
                    </header>
                </div>

                <!-- User Chat Area -->
                <vue-perfect-scrollbar
                    ref="refChatLogPS"
                    :settings="perfectScrollbarSettings"
                    class="user-chats scroll-area"
                >
                    <chat-log v-if="currentContactChat.length>0"
                              :chat-data="currentContactChat"
                              :profile-user-avatar="activeChat.files[0].full_path"
                    />
                </vue-perfect-scrollbar>

                <!-- Message Input -->
                <b-form
                    class="chat-app-form"
                    @submit.prevent="sendMessage"
                >
                    <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-input
                            v-model="chatInputMessage"
                            :placeholder="$t('Enter your message')"
                        />
                    </b-input-group>
                    <b-button
                        @click="selectImageFromDevice"
                        class="btn-icon mr-1"
                        variant="primary"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    >
                        <feather-icon icon="ImageIcon"/>
                    </b-button>
                    <b-button
                        class="btn-icon"
                        variant="primary"
                        type="submit"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    >
                        <feather-icon icon="SendIcon"/>
                    </b-button>
                </b-form>
            </div>
        </section>

        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
            <chat-left-sidebar
                :contacts="contacts"
                :active-chat-contact-id="changeableContactId ? parseInt(changeableContactId) : parseInt($route.params.id)"
                @open-chat="openChatOfContact"
            />
        </portal>
    </div>
</template>

<script>

import {
    ref, onUnmounted, nextTick,
} from '@vue/composition-api'
import {
    BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {$themeBreakpoints} from '@themeConfig'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog'
import useChat from './useChat'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from 'vue-ripple-directive'


export default {
    name: "chat",
    components: {
        // BSV
        BAvatar,
        BDropdown,
        BDropdownItem,
        BForm,
        BInputGroup,
        BFormInput,
        BButton,

        // 3rd Party
        VuePerfectScrollbar,

        // SFC
        ChatLeftSidebar,
        ChatActiveChatContentDetailsSidedbar,
        ChatLog,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            changeableContactId: '',
            activeChat: null,
            chatInputMessage: '',
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
            },
            isStartConversation: false,
            lastImageMessage: null,
            isImageMessage: false,
        }
    },
    computed: {
        contacts() {
            // return this.$store.state.chat.contacts;
            return this.$store.state.chat.filteredContacts.length > 0 ?
                this.$store.state.chat.filteredContacts : this.$store.state.chat.contacts;
        },
        currentContactChat() {
            return this.$store.state.chat.contactChat;
        },
    },
    methods: {
        sendMessage(event) {
            //Check if the message is image message
            if (this.lastImageMessage !== null) {
                this.isImageMessage = this.lastImageMessage.name === this.chatInputMessage;
            }
            //Send Text Message
            if (this.chatInputMessage !== null && this.chatInputMessage !== "" && !this.isImageMessage) {
                this.$store.dispatch("chat/sendMessage", {
                    type: "text",
                    complaint_id: this.changeableContactId ? this.changeableContactId : this.$route.params.id,
                    message: this.chatInputMessage,
                    file_path: '',
                })
                    .then(res => {
                        this.chatInputMessage = "";
                        this.scrollToBottomInChatLog()
                        this.updateAllContacts();
                    })
                    .catch(err => {
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: this.$t('Error'),
                                    icon: 'InfoIcon',
                                    text: err.response.data.message,
                                    variant: 'danger',
                                },
                            },
                            {
                                position: 'bottom-right',
                            })
                    })
            }
            //Send Image Message
            if (this.isImageMessage) {
                let formData = new FormData();
                formData.append("file", this.lastImageMessage);
                this.$store.dispatch("chat/uploadImage", formData)
                    .then(res => {
                        let imagePath = res.data.filePath
                        this.$store.dispatch("chat/sendMessage", {
                            type: "image",
                            complaint_id: this.changeableContactId ? this.changeableContactId : this.$route.params.id,
                            message: '',
                            file_path: imagePath,
                        })
                            .then(res => {
                                this.chatInputMessage = "";
                                this.scrollToBottomInChatLog()
                                this.updateAllContacts();
                            })
                            .catch(err => {
                                this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: this.$t('Error'),
                                            icon: 'InfoIcon',
                                            text: err.response.data.message,
                                            variant: 'danger',
                                        },
                                    },
                                    {
                                        position: 'bottom-right',
                                    })
                            })
                    })
                    .catch(err => {
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: this.$t('Error'),
                                    icon: 'InfoIcon',
                                    text: err.response.data.message,
                                    variant: 'danger',
                                },
                            },
                            {
                                position: 'bottom-right',
                            })
                    })

            }

        },
        startConversation() {
            this.isStartConversation = true;
        },
        openChatOfContact(contactID) {
            this.$store.dispatch("chat/getChatsById", contactID)
                .then(res => {
                    this.changeableContactId = contactID;
                    for (let i = 0; i < this.contacts.length; i++) {
                        const contact = this.contacts[i];
                        if (contact.id == contactID) {
                            this.activeChat = contact;
                        }
                    }
                    nextTick(() => {
                        this.scrollToBottomInChatLog()
                    })
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        },
        scrollToBottomInChatLog() {
            if (this.$refs != null) {
                const refChatLogPS = this.$refs.refChatLogPS;
                // console.log("Scroll Bottom Element: "+refChatLogPS.$el)
                //const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
                if (refChatLogPS !== undefined) {
                    let scrollEl = refChatLogPS.$el;
                    scrollEl.scrollTop = scrollEl.scrollHeight;
                }
            }
        },
        updateAllContacts() {
            this.$store.dispatch("chat/getContacts")
                .then(res => {
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        },
        selectImageFromDevice() {
            var input = document.createElement('input');
            input.type = 'file';
            input.accept = '.jpeg, .jpg, .png';
            input.click();
            input.onchange = e => {
                // getting a hold of the file reference
                var file = e.target.files[0];
                let fileSize = file.size;
                if (fileSize < 1572864) {
                    this.lastImageMessage = file;
                    this.chatInputMessage = file.name;
                    this.isImageMessage = true;
                } else {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: this.$t('The maximum file size is 1.5 megabyte!!'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                }
            }
        },
        getChatMessages() {
            this.$store.dispatch("chat/getChatsById", this.$route.params.id)
                .then(res => {
                    for (let i = 0; i < this.contacts.length; i++) {
                        const contact = this.contacts[i];
                        if (contact.id == this.$route.params.id) {
                            this.activeChat = contact;
                        }
                    }

                    // Scroll to bottom
                    nextTick(() => {
                        this.scrollToBottomInChatLog()
                    })
                    // console.table(this.contacts);

                    if (this.currentContactChat.length < 1) {
                        this.getSingleChat();
                    }
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: "Error from getChatsById",
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                });
        },
        getSingleChat() {
            this.$store.dispatch("chat/getSingleContactInfo", this.$route.params.id)
                .then(res => {
                    debugger;
                    for (let i = 0; i < this.contacts.length; i++) {
                        const contact = this.contacts[i];
                        if (contact.id == this.$route.params.id) {
                            this.activeChat = contact;
                        }
                    }
                    this.scrollToBottomInChatLog()
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: this.$t('Something went wrong'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                });
        },
    },
    created() {
        //Opened Complaint ID

        //Get All Contacts (Complaints)
        this.$store.dispatch("chat/getContacts")
            .then(res => {
                this.getChatMessages();
            })
            .catch(err => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Error'),
                            icon: 'InfoIcon',
                            text: err.response.data.message,
                            variant: 'danger',
                        },
                    },
                    {
                        position: 'bottom-right',
                    })
            })

        //Get messages


        // get messages every 10 second
        // setInterval(() => {
        //     if (defaultContactID !== null || defaultContactID !== undefined) {
        //         this.$store.dispatch("chat/getChatsById", this.$route.params.id)
        //             .then(res => {
        //                 this.activeChat = this.contacts.find(item => item.id == defaultContactID);
        //                 console.log(this.activeChat);
        //             })
        //             .catch(err => {
        //                 this.$toast({
        //                         component: ToastificationContent,
        //                         props: {
        //                             title: this.$t('Error'),
        //                             icon: 'InfoIcon',
        //                             text: err.response.data.message,
        //                             variant: 'danger',
        //                         },
        //                     },
        //                     {
        //                         position: 'bottom-right',
        //                     })
        //             })
        //     }
        // }, 10000)

    }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
