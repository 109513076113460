<template>

    <div class="chats">
        <div
            v-for="(msgGrp, index) in chatData"
            :key="msgGrp.senderId+String(index)"
            class="chat"
            :class="{'chat-left': !msgGrp.is_sent_by_me}"
        >
            <div class="chat-avatar">
                <b-avatar
                    size="36"
                    class="avatar-border-2 box-shadow-1"

                    :src="msgGrp.user?msgGrp.user.full_path:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'"
                />
            </div>
            <div class="chat-body">
                <div class="chat-content" v-if="msgGrp.type==='image'">
                    <b-img :src="msgGrp.full_path"
                           fluid rounded alt="Message Image"
                    ></b-img>
                </div>
                <div v-else
                     class="chat-content"
                >
                    <p>{{ msgGrp.message }}</p>
                </div>

            </div>
        </div>
    </div>

</template>

<script>

import {computed} from '@vue/composition-api'
import {BAvatar, BImg} from 'bootstrap-vue'

export default {
    name: "ChatLog",
    components: {
        BAvatar,
        BImg,
    },
    props: {
        chatData: {
            type: Array,
            required: true,
        },
        profileUserAvatar: {
            type: String,
            required: true,
        },
    },
    created() {
        // console.table(this.chatData);
    }
}
</script>

<style scoped>

</style>
