<template>
    <div class="sidebar-left">
        <div class="sidebar">

            <!-- Sidebar Content -->
            <div
                class="sidebar-content"
            >

                <!-- Header -->
                <div class="chat-fixed-search">
                    <div class="d-flex align-items-center w-100">
                        <div class="sidebar-profile-toggle">
                            <b-avatar
                                size="42"
                                class="cursor-pointer badge-minimal avatar-border-2"
                                :src="userData.avatar"
                                variant="transparent"
                                badge
                                badge-variant="success"
                            />
                        </div>
                        <!-- Search -->
                        <b-input-group class="input-group-merge ml-1 w-100 round">
                            <b-input-group-prepend is-text>
                                <feather-icon
                                    icon="SearchIcon"
                                    class="text-muted"
                                />
                            </b-input-group-prepend>
                            <b-form-input
                                v-model="searchQuery"
                                :placeholder="$t('Search...')"
                            />
                        </b-input-group>
                    </div>
                </div>

                <!-- ScrollArea: Chat & Contacts -->
                <vue-perfect-scrollbar
                    :settings="perfectScrollbarSettings"
                    class="chat-user-list-wrapper list-group scroll-area"
                >

                    <!-- Chats Title -->
                    <h4 class="chat-list-title">
                        {{$t('Chats')}}
                    </h4>

                    <!-- Chats -->
                    <ul class="chat-users-list chat-list media-list">
                        <chat-contact
                            v-for="contact in contacts"
                            :key="contact.id"
                            :user="contact"
                            tag="li"
                            :class="{'active': activeChatContactId === contact.id}"
                            is-chat-contact
                            @click="$emit('open-chat', contact.id)"
                        />
                    </ul>
                </vue-perfect-scrollbar>
            </div>

        </div>
    </div>
</template>

<script>

import {
    BAvatar, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {ref, computed} from '@vue/composition-api'
import ChatContact from './ChatContact.vue'
import UserProfileSidebar from "@/views/apps/chat/UserProfileSidebar";

export default {
    name: "ChatLeftSidebar",
    components: {
        // BSV
        BAvatar,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,

        // 3rd party
        VuePerfectScrollbar,

        // SFC
        ChatContact,
        UserProfileSidebar,
    },
    props: {
        contacts: {
            type: Array,
            required: true,
        },
        // profileUserData: {
        //     type: Object,
        //     required: true,
        // },
        activeChatContactId: {
            type: Number,
            default: null,
        },
    },
    watch:{
        searchQuery:function (newVal,oldVal){
            // console.log(typeof newVal);
            this.$store.dispatch("chat/filterContacts",newVal)
        },
    },
    methods:{
    },
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
            searchQuery: '',
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
            }
        }
    },
}
</script>

<style scoped>

</style>
